var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"450px"}},[_c('v-card-title',[_vm._v("Account")]),_c('v-card-subtitle',[_vm._v("Account Information")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomLabel',{attrs:{"for":"email","label":"Email"}}),_c('CustomTextField',{attrs:{"id":"email","inputDisabled":"","inputIcon":"mdi-email","inputPlaceholder":"Email Address","inputType":"email","inputValue":_vm.updatePayload.email}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('CustomLabel',{attrs:{"for":"first_name","label":"First Name","required":""}}),_c('CustomTextField',{attrs:{"id":"first_name","inputPlaceholder":"First Name","validationName":"first_name","validationRules":"required","inputValue":_vm.updatePayload.first_name},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updatePayload.first_name'
                                    )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('CustomLabel',{attrs:{"for":"middle_name","label":"Middle Name"}}),_c('CustomTextField',{attrs:{"id":"middle_name","inputPlaceholder":"Middle Name","inputValue":_vm.updatePayload.middle_name},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updatePayload.middle_name'
                                    )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('CustomLabel',{attrs:{"for":"last_name","label":"Last Name","required":""}}),_c('CustomTextField',{attrs:{"id":"last_name","inputPlaceholder":"Last Name","inputValue":_vm.updatePayload.last_name,"validationName":"last_name","validationRules":"required"},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updatePayload.last_name'
                                    )}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomLabel',{attrs:{"for":"display_name","label":"Display Name"}}),_c('CustomTextField',{attrs:{"id":"display_name","inputPlaceholder":"Display Name","inputValue":_vm.updatePayload.display_name},on:{"input":function($event){return _vm.updateForm(
                                        $event,
                                        'updatePayload.display_name'
                                    )}}})],1)],1)],1),_c('v-card-actions',{staticClass:"px-3"},[_c('v-spacer',{staticClass:"d-flex justify-space-between align-end"}),_c('CustomButton',{attrs:{"block":_vm.$vuetify.breakpoint.xs,"btnColour":"primary","btnIsLoading":_vm.updateOps.isLoading,"btnLabel":"Save","tooltipMsg":"Save"},on:{"click":function($event){return _vm.updateProfile(_vm.updatePayload)}}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }