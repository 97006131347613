<template>
    <v-card min-height="450px">
        <v-card-title>Account</v-card-title>
        <v-card-subtitle>Account Information</v-card-subtitle>

        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <CustomLabel for="email" label="Email" />
                                <CustomTextField
                                    id="email"
                                    inputDisabled
                                    inputIcon="mdi-email"
                                    inputPlaceholder="Email Address"
                                    inputType="email"
                                    :inputValue="updatePayload.email"
                                />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12" sm="4">
                                <CustomLabel
                                    for="first_name"
                                    label="First Name"
                                    required
                                />
                                <CustomTextField
                                    id="first_name"
                                    inputPlaceholder="First Name"
                                    validationName="first_name"
                                    validationRules="required"
                                    :inputValue="updatePayload.first_name"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updatePayload.first_name'
                                        )
                                    "
                                />
                            </v-col>

                            <v-col cols="12" sm="4">
                                <CustomLabel
                                    for="middle_name"
                                    label="Middle Name"
                                />
                                <CustomTextField
                                    id="middle_name"
                                    inputPlaceholder="Middle Name"
                                    :inputValue="updatePayload.middle_name"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updatePayload.middle_name'
                                        )
                                    "
                                />
                            </v-col>

                            <v-col cols="12" sm="4">
                                <CustomLabel
                                    for="last_name"
                                    label="Last Name"
                                    required
                                />
                                <CustomTextField
                                    id="last_name"
                                    inputPlaceholder="Last Name"
                                    :inputValue="updatePayload.last_name"
                                    validationName="last_name"
                                    validationRules="required"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updatePayload.last_name'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col cols="12">
                                <CustomLabel
                                    for="display_name"
                                    label="Display Name"
                                />
                                <CustomTextField
                                    id="display_name"
                                    inputPlaceholder="Display Name"
                                    :inputValue="updatePayload.display_name"
                                    @input="
                                        updateForm(
                                            $event,
                                            'updatePayload.display_name'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="px-3">
                        <v-spacer
                            class="d-flex justify-space-between align-end"
                        />
                        <CustomButton
                            :block="$vuetify.breakpoint.xs"
                            btnColour="primary"
                            :btnIsLoading="updateOps.isLoading"
                            btnLabel="Save"
                            tooltipMsg="Save"
                            @click="updateProfile(updatePayload)"
                        />
                    </v-card-actions>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("userSelfMgmt", ["user", "updatePayload", "updateOps"]),
        isOAuthUser() {
            return this.user.type > 0;
        },
    },
    data: () => ({}),
    methods: {
        async updateProfile(payload) {
            let valid = await this.$refs.observer.validate();

            if (valid) {
                let response = await this.$store.dispatch(
                    "userSelfMgmt/updateSelf",
                    {
                        email: payload.email,
                        first_name: payload.first_name,
                        middle_name: payload.middle_name,
                        last_name: payload.last_name,
                        display_name: payload.display_name,
                    }
                );

                if (response) {
                    this.$refs.observer.reset();
                }
            }
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("userSelfMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
    },
    name: "TabUserProfile",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

